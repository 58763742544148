<template>
  <div id="chart">
    <base-table
        :row="dataRemainingGood"
        :rowHeight="35"
        :columnDefs="columsRemainingGood"
        :settings="{}"
        :statusBar="statusBarReportTable"
        :rowGroupPanelShow="'always'"
        :pagination="pagination"
        :entityPermission="_permissions"
        @showRowBtnClick="onShowRowBtnClick"
    >
    </base-table>
  </div>
</template>
<script>
const baseURL = process.env.VUE_APP_API_URL;
import BaseTable from "../BaseTable";
import {mapState} from "pinia";
import {useDashboardStore} from "../../stores/dashbordStore";

export default {
  name: "ReportRemainingGood",
  props: {
    _data: {
      type: Array,
      default() {
        return []
      }
    },
    _permissions:{
      type: Object,
      default(){
        return null
      }
    }
  },
  components: {
    BaseTable
  },
  data() {
    return {
      columnDefs: [],
      row: [],
      pagination: true,
      settings:{
        height: 400
      },
      baseUrl: baseURL,
    };
  },

  computed: {
    ...mapState(useDashboardStore, ['columsRemainingGood', 'dataRemainingGood', 'statusBarReportTable']),
  },

  methods: {
    onShowRowBtnClick(row) {
      if(row && row.idReceipt){
        window.open(document.location.protocol + "//" + document.location.host + '/details/receipt/' + row.idReceipt);
      }
    },
  },
  created() {
  }
  ,
  mounted() {
  },
  watch() {

  }
}
;
</script>
<style lang="scss">
.ag-theme-alpine {
  --ag-background-color: #c71e1e;
}

.ag-row-odd .ag-row .ag-row-footer .ag-row-level--1 .ag-row-position-absolute .ag-row-last .ag-after-created .ag-row-focus{
  font-size: 20px;
}

.ag-row-footer{
  font-size: 18px !important;
}
</style>
