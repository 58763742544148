<template>
  <base-switcher
    @change-value="changeValue"
    :value="value"
    :disabled="fieldDisabled"
    :readonly="fieldReadonly"
  ></base-switcher>
</template>

<script>
import abstractField from "./abstractField";
import BaseSwitcher from "@/components/BaseSwitcher";
export default {
  name: "ToogleField",
  components: {BaseSwitcher},
  props: {},
  mixins: [abstractField],
  data() {
    return {
      options: [],
    };
  },
  computed: {
    hasValue() {
      return this.value && this.value?.length > 0 ? true : false;
    },
  },
  methods: {
    cLearValue() {
      console.log("cLearValue");
      this.value = "";
    },
    changeValue(value){
      this.value = value;
    },
  },
};
</script>

<style scoped lang="scss"></style>
