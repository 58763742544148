<template>
  <div id="chart"
       style="height: 415px">
    <h2>{{ $t('dashboard.chart.sales-group-month',{currency: this._currency})}}</h2>
    <base-table
        :row="dataSalesMonth"
        :columnDefs="columsSalesMonth"
        :settings="settings"
        :pagination="pagination">
    </base-table>
  </div>
</template>
<script>
import BaseTable from "../BaseTable";
import {mapState} from "pinia";
import {useDashboardStore} from "../../stores/dashbordStore";
export default {
  name: "ReportSalesMonth",
  props: {
    _data: {
      type: Array,
      default() {
        return []
      }
    },
    _currency: {type: String, default: ''}
  },
  components: {
    BaseTable
  },
  data() {
    return {
      columnDefs: [],
      row: [],
      pagination: true,
      settings:{
        height: 400
      }
    };
  },
  computed: {
    ...mapState(useDashboardStore, ['columsSalesMonth', 'dataSalesMonth']),
  },
  methods: {},
  created() {
  },
  mounted() {
  },
  watch() {
  }
};
</script>
<style lang="scss">

</style>
