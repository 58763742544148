export const isObject = (obj) => {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
};
export const isArray = (val) => {
  return Array.isArray(val) && val !== null;
};
export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}
export function isPrimitive(inputValue) {
  if (inputValue == null) {
    return true;
  }
  if (typeof inputValue == "function" || typeof inputValue == "object") {
    return false;
  } else {
    return true;
  }
}
export function isNull(val){
  return val === null | val === undefined
}
export function hasProperty(obj, props){
  return Object.prototype.hasOwnProperty.call(obj, props);
}
