<template>
  <div id="chart">
    <apexchart v-if="!isLoading"
      type="line"
      height="250"
      :options="chartQuantityCheckOptions"
      :series="chartQuantityCheckSeries"></apexchart>
  </div>
</template>
<script>

export default {
  name: "ChartsQuantityCheck",
  props: {
    _series: {
      type: Array,
      default() {
        return []
      }
    },
    _categories: {
      type: Array,
      default() {
        return []
      }
    },
    _title: {type: String, default: ''},
    _yaxisText: {type: String, default: ''}
  },
  components: {},
  data() {
    return {
      isLoading: true,
      chartQuantityCheckSeries: [],
      chartQuantityCheckOptions: {
        chart: {
          height: 250,
          type: 'line',
          zoom: {
            enabled: true
          },
          toolbar: {
            show: false // Вимкнути панель інструментів
          }
        },
        tooltip: {
          enabled: false // Відключити tooltip
        },
        dataLabels: {
          enabled: true
        },
        stroke: {
          /*  width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]*/
        },
        title: {
          text: this._title,
          align: 'left'
        },
        legend: {
          enabled: true,
          tooltipHoverFormatter: function (val, opts) {
            return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
          }
        },
        markers: {
          enabled: true
        },
        yaxis: {
          title: {
            text: this._yaxisText
          },
        },
        xaxis: {
          categories: [15, 16, 17, 18, 19, 20],
        },

        grid: {
          borderColor: '#f1f1f1',
        }
      },
    };
  },
  methods: {
    setOptions() {
      this.chartQuantityCheckOptions = {
        ...this.chartQuantityCheckOptions, ...{
          xaxis: {
            categories: this._categories
          }
        }
      };
      this.chartQuantityCheckSeries = this._series;
      this.isLoading = false;

    }
  },
  created() {
  },
  mounted() {
    this.setOptions();
  },
  watch: {
    _categories() {
      this.setOptions();
    },
    _title(){
      this.chartQuantityCheckOptions = {
        ...this.chartQuantityCheckOptions, ...{
          title: {
            text: this._title
          }
        }
      };
    },
    _yaxisText(){
      this.chartQuantityCheckOptions = {
        ...this.chartQuantityCheckOptions, ...{
          yaxis:{
            title: {
              text: this._yaxisText
            }
          },
        }
      };
    },
  }
};
</script>
<style lang="scss"></style>
