<template>
  <div
    class="w-full flex flex-1 transition-input duration-200 border-solid form-border-width-input form-shadow-input form-input-group group"
    :class="containerStyle"
  >
    <div
      v-if="item?.addons?.before"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 form-radius-input-l form-pl-input form-pr-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.before }}
      </div>
    </div>
    <div
      v-if="item?.addons?.after"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 order-2 form-radius-input-r form-pr-input form-pl-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.after }}
      </div>
    </div>
    <base-multiselect
      class="base-multiselect__item"
      :placeholder="`Select ${item.label}`"
      :value="value"
      :items="item.items"
      :valueProp="valueProp"
      :labelProp="valueProp"
      :canClear="true"
      :closeOnSelect="true"
      @select="selectItem"
      :disabled="fieldDisabled"
      :readonly="fieldReadonly"
    ></base-multiselect>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import BaseMultiselect from "@/components/BaseMultiselect.vue";

export default {
  name: "MultiselectField",
  components: { BaseMultiselect },
  props: {},
  mixins: [abstractField],
  data() {
    return {
      options: [],
    };
  },
  computed: {
    disableadClass() {
      return this.fieldDisabled
        ? "form-bg-disabled form-color-disabled"
        : "form-bg-input form-color-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove";
    },
    containerWidth() {
      if (this.item?.size == "sm") {
        return `form-radius-input-sm form-h-input-height-sm`;
      } else if (this.item?.size == "lg") {
        return `form-radius-input-lg form-h-input-height-lg`;
      } else return "form-radius-input form-h-input-height";
    },
    containerStyle() {
      return [this.disableadClass, this.containerWidth];
    },
    inputClass() {
      if (this.item?.size == "sm") {
        return `form-p-input-sm form-text-sm  with-floating:form-p-input-floating-sm `;
      } else if (this.item?.size == "lg") {
        return `form-p-input-lg form-text-lg with-floating:form-p-input-floating-lg `;
      } else
        return "form-p-input form-text with-floating:form-p-input-floating";
    },
    valueProp(){
      let prop = Array.isArray(this.item?.items) ? "" : "name";
      if(this.item && this.item.items && this.item.items.titleName ){
        prop = this.item.items.titleName;
      }
      return prop
    }
  },
  methods: {
    selectItem(value) {
      // console.log("call", value);
      this.value = value;
    },
  },
};
</script>

<style scoped lang="scss"></style>
