<template>
  <!-- df-preview-element-container-hover -->
  <div
    class="df-preview-element-container df-preview-element-container-md col-span-12"
    :class="containerClass"   
    @mouseover.stop="hoverElement"
    @mouseleave.stop="elementHovered = false"    
  >
    <!-- <span>CustomElement</span> -->
    <div class="df-preview-element-outer-wrapper">
      <label for="text_1" class="flex items-start" :class="labelClass">
        <span v-if="!item.hideLabel">{{ item?.label }}</span><span class="requiredText" v-if="itemIsRequired">*</span>
      </label>
      <div class="df-preview-element-inner-container" :class="wraperWidth">
        <div
          v-if="item?.before"
          class="df-preview-element-inner-wrapper-before"
        >
          <div>{{ item.before }}</div>
        </div>
        <div class="df-preview-element-inner-wrapper col-span-12">
          <component :is="getFieldType(item)" :item="item" :formData="formData" :errors="errors">
          </component>
        </div>
        <div class="df-preview-element-inner-wrapper-after">
          <div
            v-if="item?.description"
            class="form-color-muted form-text-small mt-1"
          >
            {{ item.description }}
          </div>
          <div v-if="item?.after">{{ item.after }}</div>
          <div
            v-if="itemErrors"
            class="form-color-danger block form-text-small mt-1"
            id="email__error"
            aria-live="assertive"
          >
          <span class="block" v-for="(el, index) in itemErrors" :key="index">{{el.message}}</span>           
          </div>
        </div>
      </div>
    </div>
    <!-- Overlay -->
     
 
  </div>
</template>

<script>
import fieldText from "./fields/fieldText.vue";
import fieldTextarea from "./fields/fieldTextarea.vue";
import fieldGroup from "./fields/fieldGroup.vue";
import fieldTabs from "./fields/fieldTabs.vue";
import fieldStatic from "./fields/fieldStatic.vue";
import fieldCheckbox from "./fields/fieldCheckbox.vue";
import fieldRadio from "./fields/fieldRadio.vue";
import fieldMultiselect from "./fields/fieldMultiselect.vue";
import fieldTable from "./fields/fieldTable.vue";
import fieldFile from "./fields/fieldFile.vue";
import fieldDatetime from "./fields/fieldDatetime.vue";
import fieldToggle from "./fields/fieldToggle.vue";
import fieldJson from "./fields/fieldJson.vue";

export default {
  name: "CustomElement",
  components: {
    fieldText,
    fieldTextarea,
    fieldGroup,
    fieldTabs,
    fieldStatic,
    fieldCheckbox,
    fieldRadio,
    fieldMultiselect,
    fieldTable,
    fieldFile,
    fieldDatetime,
    fieldToggle,
    fieldJson
  },
  props: {
    item: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    errors: {
      type: Array,
    },   
  },
  emits: [],
  inject: [      
    "updateModelValue"
  ],
  data() {
    return {
      showDropzone: null,
      elementHovered: null,
    };
  },
  computed: {
    itemErrors(){
      if (this.item?.name && this.errors?.length > 0) {
          return this.errors.filter( el => el.fieldId === this.item.name);
      }
      else return null
    },
    itemIsRequired(){
      return this.item?.rules && this.item.rules?.includes('required');
    },
    showTopDropZone() {
      return this.showDropzone == "top";
    },
    showBottomDropZone() {
      return this.showDropzone == "bottom";
    },   
    wraperWidth() {
      return this.item?.columns?.wrapper
        ? `col-span-${this.item.columns.wrapper}`
        : "col-span-12";
    },
    containerWidth() {
      return this.item?.columns?.container
        ? `col-span-${this.item.columns.container}`
        : "col-span-12";
    },
    containerSize() {
      return this.item?.size
        ? `df-preview-element-container-${this.item.size}`
        : "df-preview-element-container-md";
    },
    elementStyle() {
      return this.item?.type == "group"
        ? "df-preview-element-container-nested df-preview-element-container-lvl0 df-preview-element-container-no-visual-nesting"
        : "form-text-type";
    },
   
    containerClass() {
      return [
        this.containerWidth,
        this.containerSize,
        this.elementStyle,       
      ];
    },
    labelSize() {
      if (this.item?.size == "sm") {
        return `form-text-sm form-pr-gutter-sm form-pb-gutter/3-sm`;
      } else if (this.item?.size == "lg") {
        return `form-text-lg form-pr-gutter-lg form-pb-gutter/3-lg`;
      } else return "form-text form-pr-gutter form-pb-gutter/3";
    },
    labelWidth() {
      return this.item?.columns?.label
        ? `col-span-${this.item.columns.label}`
        : "col-span-12";
    },
    labelClass() {
      return [this.labelSize, this.labelWidth];
    },
  },
  methods: {
    getFieldType(item) {
      // console.log("field" + item.type[0].toUpperCase()+ item.type.slice(1));
      return "field" + item.type[0].toUpperCase() + item.type.slice(1);
    },     
    hoverElement() {
      this.elementHovered = true;
    },
    
  },
  created(){
    if (this.item?.default){
      this.updateModelValue(this.item.name, this.item.default);
    }
  }
};
</script>

<style scoped lang="scss">
.df-icon {
  display: inline-block;
  height: 1em;
  overflow: visible;
  font-size: inherit;
  color: inherit;
  vertical-align: -0.125em;
}
.d-none {
  display: none !important;
}
.requiredText {
  color: rgb(236, 40, 66);
  font-weight: bold;
  font-size: 16px;
}
</style>
