<template>
  <div class="dropdown bootstrap-select show-tick">
    <button type="button" class="btn dropdown-toggle btn-light bs-placeholder"
            data-toggle="dropdown"
            role="combobox"
            aria-owns="bs-select-12"
            aria-haspopup="listbox"
            aria-expanded="false"
            title="Nothing selected"
            @click="toggleDropdown">
      <div class="filter-option">
        <div class="filter-option-inner">
          <div class="filter-option-inner-inner">{{ dropdownTitleText }}</div>
        </div>
      </div>
    </button>
    <ul v-if="showDropdown"
        ref="dropdown"
        style="border: 1px solid rgba(0, 0, 0, .15);"
        class="dropdown-menu" role="presentation">
      <li>
        <a role="option" class="dropdown-item" id="bs-select-12-0" tabindex="0" aria-selected="false"
          @click="changeAllDropdownOption()"
        >
          <span class="fa bs-ok-default check-mark"></span>
          <input
              class="v-list-item-title"
              type="checkbox"
              :checked="checkedAll"
          >
          <span class="text">Вибрати все</span>
        </a>
      </li>
      <li v-for="(option, index) in options"
          :key="index">
        <a role="option" class="dropdown-item" id="bs-select-12-0" tabindex="0" aria-selected="false"
           aria-setsize="4" aria-posinset="1"
           :class="{ active: isHover[index] }"
           @mouseover="setHoverState(index, true)"
           @mouseleave="setHoverState(index, false)"
           @click="changeDropdownOption(option.id, !option.checked)">
          <span class="fa bs-ok-default check-mark"></span>
          <input
              class="v-list-item-title"
              type="checkbox"
              :checked="option.checked"
              :value="option.id"
          >
          <span class="text" >{{ option.title }}</span></a>
      </li>
    </ul>
  </div>
</template>

<script scoped>
export default {
  name: "DropDown",
  props: {
    title: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      required: false,
    },
    status: {
      type: Boolean,
      required: false,
    }
  },
  data() {
    return {
      showDropdown: false,
      selectedOptionIds: [],
      selectedOptionsNames: [],
      payload: null,
      oldOptions: [],
      isHover: [],
      checkedAll:false,
      // selectedOptions: this.options.filter(option => option.checked).map(option => option.id),
    }
  },

  computed: {
    dropdownTitleText() {
      return `${this.options.filter(obj => obj.checked).map(obj => obj.title).join(', ').slice(0, 40)}...`;
    }
  },

  mounted() {
    document.addEventListener('mousedown', this.handleClickOutside);
    this.oldOptions = [...this.options];
  },

  methods: {
    toggleDropdown() {
      this.addRedBorder();
      this.showDropdown = !this.showDropdown;
    },
    addRedBorder() {
      this.isActive = true;
    },
    handleClickOutside(event) {
      const dropdown = this.$refs.dropdown;
      const target = event.target;
      if (dropdown && !dropdown.contains(target)) {
        this.showDropdown = false;

        if (this.payload !== null) {
          this.$emit('selection-changed', this.payload);
          this.payload = null;
        }
      }
    },
    changeDropdownOption(id) {
      const indexElement = this.options.findIndex(obj => obj.id === id);
      if (indexElement === -1) {
        return;
      }

      this.options.forEach(item => {
        if (item.id === id) {
          item.checked = !item.checked;
        }
      });

      this.changeArraySelected();
    },
    changeAllDropdownOption() {
      this.checkedAll = !this.checkedAll;
      this.options.forEach(item => {
        item.checked = this.checkedAll;
      });
      this.changeArraySelected();
    },
    changeArraySelected() {
      this.selectedOptionIds = this.options
          .filter(item => item.checked === true)
          .map(item => item.id);
      
      this.selectedOptionsNames = this.options
          .filter(item => item.checked === true)
          .map(item => item.title);

      this.payload = {
        event: 'dropdown',
        type: this.type,
        listSelected: this.selectedOptionIds,
        listSelectedNames: this.selectedOptionsNames
      };
    },
    setHoverState(index, value) {
      this.isHover[index] = value; // встановлюємо стан наведення для конкретного елемента
    },
  },
}
</script>

<style>

.bootstrap-select {
  vertical-align: middle;
}

.dropdown {
  position: relative;
}

.bootstrap-select:not(.input-group-btn), .bootstrap-select[class*=col-] {
  float: none;
  display: flex;
  margin-left: 0;
}


.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none;
  z-index: 0 !important;
}

select {
  text-transform: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.bootstrap-select > .dropdown-toggle {
  position: relative;
  width: 100%;
  text-align: right;
  white-space: nowrap;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn {
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: flex;
  grid-area: control;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.bootstrap-select .dropdown-toggle .filter-option {
  position: static;
  top: 0;
  left: 0;
  float: left;
  height: 100%;
  width: 100%;
  text-align: left;
  overflow: hidden;
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
}

.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  z-index: 999999;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
  will-change: transform;
  transform: translate3d(0px, 38px, 0px);
  top: 2px;
  border-radius: 0.25rem;
  transition: height 0.3s ease;

  max-height: 250px;
  overflow-y: scroll;
}

.bootstrap-select .dropdown-menu.inner {
  /*position: static;*/
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.bootstrap-select .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.dropdown-menu.show {
  display: block;
}

.bootstrap-select .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.bootstrap-select .dropdown-menu li a span.check-mark {
  display: none;
}

.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bootstrap-select.show-tick .dropdown-menu li a span.text {
  margin-right: 34px;
}

.bootstrap-select .dropdown-menu li a span.text {
  display: inline-block;
}

.text {
  padding-left: 7px;
}

.active{
  background-color: #f8f9fa;
}

</style>
