<template>
  <div id="chart"
       style="height: 415px; width: 100%">
    <h2>{{ $t('dashboard.chart.sales-group-point',{currency: this._currency})}}</h2>
    <base-table
        :row="dataSales"
        :columnDefs="columsSales"
        :defaultColDef="defaultColDef"
        :settings="settings"
        :pagination="pagination">
    </base-table>
  </div>
</template>
<script>
import BaseTable from "../BaseTable";
import {mapState} from "pinia";
import {useDashboardStore} from "../../stores/dashbordStore";

export default {
  name: "ReportSales",
  props: {
    _data: {
      type: Array,
      default() {
        return []
      }
    },
    _currency: {type: String, default: ''}
  },
  components: {
    BaseTable
  },
  data() {
    return {
      columnDefs: [],
      row: [],
      pagination: true,
      defaultColDef: {
        enableRowGroup: false,
        enablePivot: false,
        enableValue: false,
        width: 100,
        flex: 1,
        minWidth: 20,
        filter: false,
        sortable: true,
        resizable: true,
      },
      settings:{
        height: 400,
        showOptionsPanel: false,
      }
    };
  },

  computed: {
    ...mapState(useDashboardStore, ['columsSales', 'dataSales']),
  },

  methods: {},
  created() {
  }
  ,
  mounted() {
  },
  watch() {

  }
}
;
</script>
<style lang="scss">

</style>
