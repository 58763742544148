import { isPrimitive } from "../utils";

let transformConjunction = (cond) => {
  switch (cond) {
    case "and":
      return "&&";
    case "or":
      return "||";
    default:
      break;
  }
};

let generateCondition = (conditionObg) => {
  if (
    !Object.prototype.hasOwnProperty.call(conditionObg, "value") ||
    conditionObg.value == null
  ) {
    switch (conditionObg.operator) {
      case "empty":
        return `(!target.${conditionObg.fieldId} || target.${conditionObg.fieldId} == null || target.${conditionObg.fieldId} == 'null' || target?.${conditionObg.fieldId}?.length == 0)`;
      case "notEmpty":
        return `(target?.${conditionObg.fieldId} !== null && target?.${conditionObg.fieldId} !== 'null' && target?.${conditionObg.fieldId}?.length !== 0)`;
    }
  }
  if (isPrimitive(conditionObg.value)) {
    //value is Primitive
    switch (conditionObg.operator) {
      case "==":
        return `(target?.${conditionObg.fieldId} == ${conditionObg.value})`;
      case "!=":
        return `(target?.${conditionObg.fieldId} != ${conditionObg.value})`;
      case ">":
        return `(target?.${conditionObg.fieldId} > ${conditionObg.value})`;
      case ">=":
        return `(target?.${conditionObg.fieldId} >= ${conditionObg.value})`;
      case "<":
        return `(target?.${conditionObg.fieldId} < ${conditionObg.value})`;
      case "<=":
        return `(target?.${conditionObg.fieldId} <= ${conditionObg.value})`;
      default:
        return "value is Primitive";
    }
  } else if (Array.isArray(conditionObg.value)) {
    let strVal = "";
    conditionObg.value.forEach((element) => {
      strVal = strVal + `'${element}',`;
    });
    switch (conditionObg.operator) {
      case "in":
        return `([${strVal}].includes(target.${conditionObg.fieldId}))`;
      case "not_in":
        return `([${strVal}].includes(target.${conditionObg.fieldId}) === false)`;
      default:
        break;
    }
  } else {
    console.warn("vulue is Object");
    return "vulue is Object";
  }
};

let generateConditionGroup = (rulesSet, conjunction, level = null) => {
    let resalt = "";
  if (Array.isArray(rulesSet)) {
    rulesSet.forEach((el, index) => {    
      if (level) {
        resalt = resalt + 'return ';
        level = false
      }
      if (Object.prototype.hasOwnProperty.call(el, "rulesSet")) {
        resalt = resalt + generateConditionGroup(el.rulesSet, el.conjunction, false);
      }
      else {
        resalt = resalt + generateCondition(el);
      }
      if ( index !== rulesSet.length-1) {
        resalt = resalt + transformConjunction(conjunction);
      }
    });
  }
  return resalt;
};

export function checkConditions(target, conditionsSet) { 
  // let resalt = "return ";
  let conditionStr = generateConditionGroup(conditionsSet.rulesSet, conditionsSet.conjunction, true);
  console.log("conditionStr",conditionStr);
  let fun = new Function("target", conditionStr);
  return fun(target);
  // console.log(target, resalt);
  // return true;
}


// "return  model && model.ID3e667edead1f40779abf0dd4bd5caa8d && model.ID3e667edead1f40779abf0dd4bd5caa8d.ID == 'ID2599a85d6cc94d969c9f699902bb15ac' ||  model && model.ID3e667edead1f40779abf0dd4bd5caa8d && model.ID3e667edead1f40779abf0dd4bd5caa8d.ID == 'IDa05c1461103e4f4a9030d10c5cdf7108' ||  model && model.ID3e667edead1f40779abf0dd4bd5caa8d && model.ID3e667edead1f40779abf0dd4bd5caa8d.ID == 'ID6ff9a04a95c94d65b573747c66305493' ||  model && model.ID3e667edead1f40779abf0dd4bd5caa8d && model.ID3e667edead1f40779abf0dd4bd5caa8d.ID == 'ID8b3817e3554c4865a854a896fe296b57' ||  model && model.ID3e667edead1f40779abf0dd4bd5caa8d && model.ID3e667edead1f40779abf0dd4bd5caa8d.ID == 'ID7a44846c03ef4e918cd73d74ff381739';"

//   export function fieldVisible(field) {
//     let self = this;
// if (!isNil(field) && !isNil(field.visible) && field.visible[0] == 'r'){
//     let fun = new Function("model", field.visible);
//     let res =  fun.call(self, self.model, field, self);
//     if (!res && self.model && self.model.hasOwnProperty(field.model) && !isNil(this.model[field.model]) && !isEmpty(this.model[field.model])){
//         this.model[field.model] = [];
//     }
//     return res ? true : false
//     }
// if (!isNil(field) && _.isString(field.visible) & !_.has(this.model, field.visible )) {return false  }
// if (!isNil(field) && isFunction(field.visible)) return field.visible.call(this, this.model, field, this);
// if (!isNil(field) && isNil(field.visible)) return true;
// return !isNil(field) ? field.visible : false;
// }
