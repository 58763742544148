<template>
  <div id="chart"
       class="d-flex flex-column charts-price"
       style="cursor: pointer; padding: 5px;">
    <apexchart v-if="!isLoading"
               :type="typeChart"
               height="650"
               :options="chartPriceOptions"
               :series="chartPriceSeries"
               @animationEnd="closeSpinner">
    </apexchart>
    <spinner v-if="showSpinner"></spinner>
  </div>
</template>
<script>

import Spinner from "../Spinner.vue";

export default {
  name: "DefaultChart",
  props: {
    _series: {
      type: Array,
      default() {
        return []
      }
    },
    _categories: {
      type: Array,
      default() {
        return []
      }
    },
    typeChart:{
      type: String,
      default: 'line'
    }
  },
  computed: {},
  components: {
    Spinner
  },
  data() {
    return {
      isLoading: true,
      showSpinner:false,
      chartPriceSeries: [],
      chartPriceOptions: {
        chart: {
          height: 650,
          width: '100%',
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: true // Вимкнути панель інструментів
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        markers: {
          size: 0,
        },
        title: {
          text: '',
          align: 'left'
        },
        legend: {
          show: false,
        },
        tooltip: {
          enabled: true
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [],
        }
      },
    };
  },
  methods: {
    setOptions() {
      console.log("Show chart")
      this.showSpinner = true;


      this.chartPriceOptions = {
        ...this.chartPriceOptions, ...{
          xaxis: {
            categories: this._categories
          }
        }
      };
      
      this.chartPriceSeries = this._series;
      this.isLoading = false;

      if((this._series.length > 0) && ( this._series[0].data && (this._series[0].data.length < 1) )){
        this.closeSpinner();
      }

    },
    closeSpinner(){
      this.showSpinner = false;
    }
  },
  created() {
  },
  mounted() {
    this.setOptions();
  },
  watch: {
    _series() {
      this.setOptions();
    }
  }
};
</script>
<style lang="scss">
</style>
