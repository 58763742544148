<template>
  <div id="chart"
       class="d-flex flex-column charts-price"
       style="cursor: pointer; padding: 5px;">
    <apexchart v-if="!isLoading"
               type="line"
               height="250"
               :options="chartPriceOptions"
               :series="chartPriceSeries"
               @click="handleClick()">
    </apexchart>
  </div>
</template>
<script>

export default {
  name: "chartPrice",
  props: {
    _series: {
      type: Array,
      default() {
        return []
      }
    },
    _categories: {
      type: Array,
      default() {
        return []
      }
    },
    _title: {type: String, default: ''},
    _yaxisText: {type: String, default: ''}
  },
  computed: {},
  components: {},
  data() {
    return {
      isLoading: true,
      chartPriceSeries: [],
      chartPriceOptions: {
        chart: {
          height: 250,
          type: 'line',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false
          },
          dropShadow: {
            enabled: true,
            color: '#de1616',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
        },
        tooltip: {
          enabled: false
        },
        dataLabels: {
          enabled: true
        },
        stroke: {},
        title: {
          text: this._title,
          align: 'left'
        },
        markers: {
          enabled: false
        },
        yaxis: {
          title: {
            text: this._yaxisText,
          },
        },
        xaxis: {
          categories: [15, 16, 17, 18, 19, 20],
        },
        grid: {
          borderColor: '#f1f1f1',
        }
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        floating: true,
        offsetY: -25,
        offsetX: -5
      }
    };
  },
  methods: {
    setOptions() {
      this.chartPriceOptions = {
        ...this.chartPriceOptions, ...{
          xaxis: {
            categories: this._categories
          }
        }
      };
      this.chartPriceSeries = this._series;
      this.isLoading = false;
    },
    handleClick() {
      this.$emit('event-click-chart', "chartPrice");
    }
  },
  created() {
  },
  mounted() {
    this.setOptions();
  },
  watch: {
    _series() {
      this.setOptions();
    },
    _title(){
      this.chartPriceOptions = {
        ...this.chartPriceOptions, ...{
          title: {
            text: this._title
          }
        }
      };
    },
    _yaxisText(){
      this.chartPriceOptions = {
        ...this.chartPriceOptions, ...{
          yaxis:{
            title: {
              text: this._yaxisText
            }
          },
        }
      };
    },
  }
};
</script>
<style lang="scss">

.charts-price .vue-apexcharts .apexcharts-canvas {
  position: initial !important;
}

.charts-price .apexcharts-legend {
  display: none !important;
}

.apexcharts-svg {
  transform: none
}

</style>
