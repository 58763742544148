let ErrorsText = {
  fieldIsRequired: "The field is required",
  invalidEmail: "Невірний e-mail адрес!",
  invalidFormat: "Невірний формат!",
};
function msg(text) {
  if (text != null && arguments.length > 1) {
    for (let i = 1; i < arguments.length; i++) {
      text = text.replace("{" + (i - 1) + "}", arguments[i]);
    }
  }
  return text;
}
function checkEmpty(value, messages = ErrorsText) {
  if (!value || value === "" || value.length <= 0) {
    return msg(messages.fieldIsRequired);
  }
  return null;
}

export const validators = {
  required(value, messages = ErrorsText) {
    return checkEmpty(value, messages);
  },
  number(value, messages = ErrorsText) {
    if (isFinite(value)) {
      return null;
    } else {
      return msg(messages.invalidFormat);
    }
  },
  email(value, messages = ErrorsText) {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line no-useless-escape
    if (!re.test(value)) {
      return msg(messages.invalidEmail);
    }
  },
};
