<template>
  <div class="df-center-wrapper" ref="modalDinamicForm">
    <div class="df-preview-container">
      <div
          class="df-preview-wrapper"
          tabindex="-1"
      >
        <form class="df-preview-form">
          <div class="grid grid-cols-12 form-gap-x-gutter form-gap-y-gutter">
            <template v-for="item in schema" :key="item.id">
              <custom-element
                  v-if="showfield(item)"
                  :item="item"
                  :formData="formData"
                  :errors="errors"
              ></custom-element>
            </template>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import CustomElement from "@/components/formGenerator/builder/CustomElement";
import {checkConditions,} from "@/components/formGenerator/builder/utils";

export default {
  name: "DinamicForm",
  components: {
    CustomElement,
  },
  props: {
    schema: {
      type: Array,
    },
    data: {
      type: Object,
    },
    config: {
      type: Object,
      default() {
        return {
          validateOn: "submit", //change
        };
      },
    },
  },
  inject: [],
  emits: ["model-updated", "validation-error"],
  data() {
    return {
      formData: {},
      errors: [
        // {
        //   fieldId: "number_input",
        //   message: "The Email must be a valid email address.",
        // }

      ],
      validateAllField: false
    };
  },
  methods: {
    showfield(field) {
      if (!field.visible || Object.keys(field.visible).length <= 0) {
        return true;
      } else {
        return checkConditions(this.formData, field.visible);
      }
    },

    ////form methods///
    updateModelValue(fieldName, newValue) {
      // console.log("updateModelValue", fieldName, typeof newValue, newValue);
      this.formData[fieldName] = newValue;
      this.$emit("model-updated", fieldName, newValue);
    },
    validateForm() {
      console.log('validateForm');
      this.validateAllField = true;
    },
    setError(error) {
      this.errors.push(error);
      this.$emit('validation-error', this.errors)
    },
    clearValidationErrors() {
      this.errors?.splice(0);
    },
    clearValidationErrorsForField(fieldId) {
      this.errors = this.errors?.filter((el) => el.fieldId !== fieldId);
    },
  },
  provide() {
    return {
      updateModelValue: this.updateModelValue,
      validateAllField: computed(() => this.validateAllField),
      setError: this.setError,
      clearValidationErrors: this.clearValidationErrors,
      clearValidationErrorsForField: this.clearValidationErrorsForField,
    };
  },
  created() {
    this.formData = this.data;
  },
  mounted() {
    const dinamicForm = this.$refs.modalDinamicForm;
    const dinamicFormHeight = dinamicForm.clientHeight;
    this.$emit("getFormHeight", dinamicFormHeight);

  }
};
</script>

<style lang="scss">
@import "@/components/formGenerator/builder/styles/main.scss";

.df-preview-wrapper {
  box-shadow: none !important;
}

.df-preview-container {
  margin-top: 0rem;
  margin-bottom: 0rem;
}
</style>
