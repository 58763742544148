<template>
  <div class="v-theme--ORANGE_THEME v-card--density-default elevation-10 rounded-md v-card--variant-elevated">
    <div class="v-card-text" style="padding: 10px 5px 10px 5px;">
      <div class="v-row">
        <div class="v-col-sm-4 v-col-12" v-if="dates">
          <date-field :countDay="countDaySelecter"
                      @selected-date="handleSelectionDate"></date-field>
        </div>
        <div v-if="device && device.length > 0"
             class="v-col-sm-4 v-col-12">
          <drop-down :options="device"
                     :title="title"
                     :type="'device'"
                     @selection-changed="handleSelectionChanged"
          >
          </drop-down>
        </div>
        <div v-if="item && item.length > 0"
             class="v-col-sm-3 v-col-12">
          <drop-down v-if="item"
                     :options="item"
                     :title="title"
                     :type="'item'"
                     @selection-changed="handleSelectionChanged">
          </drop-down>
        </div>
        <div class="v-col-sm-1 v-col-12 d-flex justify-content-center">
          <button type="button"
                  class="v-btn v-theme--ORANGE_THEME bg-primary v-btn--density-default v-btn--size-default v-btn--variant-flat"
                  @click="handleClearDate">
            <span class="v-btn__overlay"></span>
            <span class="v-btn__underlay"></span>
            <span class="v-btn__content" data-no-activator="">
              {{ $t('buttons.reset') }}
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from "./DropDown";
import DateField from "./DateField";

export default {
  name: "Settings",
  components: {
    DropDown,
    DateField
  },
  props: {
    countDay: {
      type: Number,
      default: 7
    },
    dates: {
      type: Boolean,
      default: true,
    },
    device: {
      type: Array,
      default() {
        return []
      }
    },
    item: {
      type: Array,
      default() {
        return []
      }
    }
  },
  inject: [],

  data() {
    return {
      title: 'Selected',
      options: [],
      optionsTwo: [],
      countDaySelecter: 7,
    };
  },
  computed: {},
  methods: {
    handleSelectionChanged(payload) {
      this.$emit('event-setting', payload);
    },
    handleSelectionDate(payload) {
      this.$emit('event-setting', payload);
    },
    handleClearDate() {
      const payload = {
        event: 'clear',
      };

      this.$emit('event-setting', payload);
    },
  },
  created() {
  },
  mounted() {
    this.countDaySelecter = this.countDay;
  },
};
</script>

<style>
</style>
