<template>
  <div id="chart" style="cursor: pointer">
    <apexchart type="treemap"
      height="400"
      :options="chartOptions"
      :series="_series"></apexchart>
  </div>
</template>
<script>
export default {
  name: "TreemapCharts",
  props: {
    _series: {
      type: Array,
      default() {
        return []
      }
    },
    _categories: {
      type: Array,
      default() {
        return []
      }
    },
    _title: {type: String, default: ''}
  },
  components: {},
  data() {
    return {
      series: [
        {
          data: [
            {
              x: 'New Delhi',
              y: 218
            },
            {
              x: 'Kolkata',
              y: 149
            },
            {
              x: 'Mumbai',
              y: 184
            },
            {
              x: 'Ahmedabad',
              y: 55
            },
            {
              x: 'Bangaluru',
              y: 84
            },
            {
              x: 'Pune',
              y: 31
            },
            {
              x: 'Chennai',
              y: 70
            },
            {
              x: 'Jaipur',
              y: 30
            },
            {
              x: 'Surat',
              y: 44
            },
            {
              x: 'Hyderabad',
              y: 68
            },
            {
              x: 'Lucknow',
              y: 28
            },
            {
              x: 'Indore',
              y: 19
            },
            {
              x: 'Kanpur',
              y: 29
            }
          ]
        }
      ],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 400,
          type: 'treemap',
          zoom: {
            enabled: false
          },
          toolbar: {
            show: false // Вимкнути панель інструментів
          },
        },
        title: {
          text: this._title
        }
      }
    };
  },
  methods: {},
  created() {
  },
  mounted() {
  },
  watch: {
    _title() {
      this.chartOptions = {
        ...this.chartOptions, ...{
          title: {
            text: this._title
          }
        }
      };
    },
  }
};
</script>
<style lang="scss"></style>
