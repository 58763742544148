<template>
  <div
    class="grid grid-cols-12 form-gap-gutter df-preview-nested-element-wrapper"
  >
    <template v-if="item.schema?.length > 0">
      <template v-for="field in item.schema" :key="field.id">
        <custom-element
          v-if="showfield(field)"
          :item="field"
          :formData="formData"
          :activeElementId="activeElementId"
          :errors="errors"
          @remove-element="removeElement"
        ></custom-element>
      </template>
    </template>
  </div>
  <div
    class="df-preview-element-middle-drag-label-container"
    v-if="showDropArea"
    @dragenter.prevent.stop="onDragEnter"
    @dragleave.prevent.stop="onDragLeave"
    @drop.stop="onDrop($event, 'group')"
  >
    <div
      class="df-preview-element-drag-label-wrapper df-preview-element-drag-label-wrapper-middle"
      :class="{ 'df-preview-element-drag-label-wrapper-visible': showDropzone }"
    >
      <div class="df-preview-element-drag-label">Drag Here</div>
    </div>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import { checkConditions } from "@/components/formGenerator/builder/utils";
export default {
  // df-preview-element-drag-label-wrapper-visible
  name: "fieldGroup",
  components: {},
  props: {
    item: {
      type: Object,
    },
     errors: {
      type: Array,
    },
  },
  inject: ["activeElementId", "emitDropElement", "editorMode"],
  mixins: [abstractField],
  data() {
    return {
      showDropzone: false,
    };
  },
  computed: {
    showDropArea() {
      return this.item?.schema?.length > 0 ? false : true;
    },
  },
  methods: {
    showfield(field) {
      if (this.editorMode) {
        return true;
      } else if (!field.visible || Object.keys(field.visible).length <= 0) {
        return true;
      } else {
        return checkConditions(this.formData, field.visible);
      }
    },
    removeElement() {
      console.log("removeElement from comtainer");
    },
    onDragEnter() {
      this.showDropzone = true;
    },
    onDragLeave() {
      this.showDropzone = null;
    },
    onDrop(event, targetZone) {
      this.showDropzone = null;
      let target = {
        id: this.item.id,
        type: targetZone,
      };
      this.emitDropElement(event, target);
      // console.log("onDrop", event, target);
    },
  },
};
</script>

<style scoped lang="scss">
.container-element {
  min-height: 60px;
  background-color: rgb(231 234 234);
  padding-left: 5px;
  padding-right: 5px;
}
</style>
