<template>
  <div
    class="grid grid-cols-12 form-gap-gutter df-preview-nested-element-wrapper"
  >
    <div
      class="tabs-label-container col-span-12 flex df-preview-element-container"
      v-if="item.schema?.length > 0"
    >
      <div 
        class="tabs-label-item mr-1 cursor-pointer"
        :class="{'tab-active':activeTab == tabs.id}"
        v-for="tabs in item.schema" :key="tabs.id"
        @click.stop="changeActiveTab(tabs)"
      >{{tabs.label}}</div>
      <div class="cursor-pointer" @click.stop="addNewTab">+</div>
     
    </div>
    <template v-if="visibleTab?.length > 0">
      <template v-for="field in visibleTab" :key="field.id">
        <custom-element
          v-if="showfield(field)"
          :item="field"
          :formData="formData"
          :activeElementId="activeElementId"
          :errors="errors"
          @remove-element="removeElement"
        ></custom-element>
      </template>
    </template>
  </div>
  <div
    class="df-preview-element-middle-drag-label-container"
    v-if="showDropArea"
    @dragenter.prevent.stop="onDragEnter"
    @dragleave.prevent.stop="onDragLeave"
    @drop.stop="onDrop($event, 'group')"
  >
    <div
      class="df-preview-element-drag-label-wrapper df-preview-element-drag-label-wrapper-middle"
      :class="{ 'df-preview-element-drag-label-wrapper-visible': showDropzone }"
    >
      <div class="df-preview-element-drag-label">Drag Here</div>
    </div>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import { uuid } from "vue-uuid";
import { checkConditions } from "@/components/formGenerator/builder/utils";
export default {
  // df-preview-element-drag-label-wrapper-visible
  name: "fieldTabs",
  components: {},
  props: {
    item: {
      type: Object,
    },
    errors: {
      type: Array,
    },
  },
  inject: ["activeElementId", "emitDropElement", "editorMode", "emitSetElementActive", "emitAddElementProperty"],
  mixins: [abstractField],
  data() {
    return {
      showDropzone: false,
      activeTab:null
    };
  },
  computed: {
    showDropArea() {
      return this.item?.schema?.length > 0 ? false : true;
    },
    visibleTab(){
        if (this.activeTab && this.item?.schema?.length > 0){
            return this.item.schema.filter( el => el.id === this.activeTab);
        }
        else return null
    }   
  },
  methods: {
    changeActiveTab(tabs){        
        this.activeTab = tabs.id;
        if(this.editorMode){
            this.emitSetElementActive(tabs)
        }        
    },
    findDefaultActiveTab(tabs){
        if (this.item?.defaultTab && this.item?.defaultTab?.length > 0) {
            this.activeTab = this.item?.defaultTab;
        }
        else {
            console.log(tabs);
             this.activeTab = tabs[0].id;
        }
    },
    addNewTab(){
        const newTab = {
            id: uuid.v4(),           
            type: 'group',
            hideLabel:true,
            label: 'Tab'+ (this.item.schema.length + 1),
            schema:[]
        }
        this.emitAddElementProperty(this.item.id, "schema", newTab);       
    },
    showfield(field) {
      if (this.editorMode) {
        return true;
      } else if (!field.visible || Object.keys(field.visible).length <= 0) {
        return true;
      } else {
        return checkConditions(this.formData, field.visible);
      }
    },
    removeElement() {
      console.log("removeElement from comtainer");
    },
    onDragEnter() {
      this.showDropzone = true;
    },
    onDragLeave() {
      this.showDropzone = null;
    },
    onDrop(event, targetZone) {
      this.showDropzone = null;
      let target = {
        id: this.item.id,
        type: targetZone,
      };
      this.emitDropElement(event, target);
      // console.log("onDrop", event, target);
    },
  },
  created(){
    if (this.item.schema?.length > 0){
        this.findDefaultActiveTab(this.item.schema);
    }
  }
};
</script>

<style scoped lang="scss">
.container-element {
  min-height: 60px;
  background-color: rgb(231 234 234);
  padding-left: 5px;
  padding-right: 5px;
}
.tabs-label-item {
    padding: 0 8px 8px 10px;
}
.tab-active {
    border-bottom: 2px solid #999;
}
</style>
