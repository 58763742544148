import { validators} from "@/components/formGenerator/builder/utils";
// console.log(validators,"validators");
function convertValidator(validator) {
    // console.log("validator",validator);
	if (validator) {
		if (validators[validator] != null) return validators[validator];
		else {
			console.warn(`'${validator}' is not a validator function!`);
			return null; // caller need to handle null
		}
	}
	return validator;
}

export default {
  props: {
    item: {
      type: Object,
    },
    formData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  inject: ["updateModelValue", "validateAllField", "setError", "clearValidationErrorsForField"],
  watch:{
    validateAllField(){
        // console.log('nedd validate');
        this.validate(this.item, this.value);
    }
  },
  computed: {
    value: {
      cache: false,
      get() {
        const valueKey = this.item?.name;
        if (this.formData && this.formData[valueKey]) {
          return this.formatValueToField(this.formData[valueKey]);
        } else return null;
      },

      set(newValue) {
        newValue = this.formatValueToModel(newValue);
        // let oldValue = this.value;
        // console.log("this.item",this.item);
        if (this.item?.rules?.length > 0 && !this.fieldDisabled && !this.fieldReadonly) {
          this.validate(this.item, newValue);
        }
        this.updateModelValue(this.item?.name, newValue);
      },
    },
    fieldId() {
      return this.item?.id ? this.item.id : this.item.name;
    },
    fieldDisabled() {
      return this.item?.disabled ? true : false;
    },
    fieldReadonly() {
      return this.item?.readonly ? true : false;
    },
  },
  methods: {
    validate(item, value) { 
      // console.log('item in validator',this.item);
        let result = false;
        let errors = [];       
        this.clearValidationErrorsForField(item.name);
        let validators = [];
        item.rules?.forEach(element => {
            validators.push(convertValidator(element).bind(this));
        });
        validators?.forEach(validator => {
            let res = validator(value);
            if (res){
                let errorObj = {
                    "fieldId": item.name,
                    "message": res
                }
                this.setError(errorObj);
            }
            // console.log(res);
        })     
    //   let validationsRes = validators(item.rules, value);    
      return {"result":result, "errors":errors}
    },
    formatValueToField(value) {
      return value;
    },

    formatValueToModel(value) {
      return value;
    },
  },
  created() {},
};
