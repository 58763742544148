<template>
  <div>
    <zoomer-photo :file_url="[fileUrl]" :show="showZoom" @closing="closeModal"></zoomer-photo>
    <label id="file_loader" for="file">
      <div
        v-if="!hidingLoadBtn || !hasValue"
        aria-describedby="file__description file__info"
        aria-invalid="false"
        aria-errormessage="file__error"
        aria-disabled="false"
        aria-busy="false"
        class="form-border-width-btn form-shadow-btn inline-block transition focus:outline-zero form-p-btn form-radius-btn form-text form-bg-btn-secondary form-color-btn-secondary form-border-color-btn-secondary cursor-pointer transition-transform transform hover:scale-105 focus:form-ring"
        aria-labelledby="file__label"
        aria-placeholder="Select file"
        role="button"
        tabindex="0"
        
      >
        {{textBtn}}
      </div>
    </label>
    
    <input v-if="!hidingLoadBtn || !hasValue"
      id="file"
      type="file"
      name="file"
      style="display: none"
      :accept="acceptTypes"
      :multiple="multiselect"
      @change="openUploadDialog"
    />
    <div
      v-if="hidingLoadBtn && hasValue"
      class="flex justify-center flex-row group relative"
      aria-describedby="file-file-description"
      aria-invalid="false"
      aria-errormessage="file__error"
      aria-disabled="false"
      aria-busy="false"
      tabindex="0"
      role="button"
      aria-labelledby="file__label"
    >
      <span
        id="file-file-description"
        class="form-assistive-text"
        aria-hidden=""
        >Press Backspace to remove</span
      >
      <div class="flex justify-between items-center w-full">
        <div class="flex items-center">
          <!-- <a           
            :href="fileUrl"
            class="hover:underline"
            target="_blank"
            rel="nofollow noopener"
          >
            <img
              v-if="item.fileType === 'image'"
              class="form_item__img"
              :src="fileUrl"
              :alt="value"
            />
          </a> -->

          <a           
            class="hover:underline"
            target="_blank"
            rel="nofollow noopener"
          >
            <img
              @click="imageZoom"
              v-if="item.fileType === 'image'"
              class="form_item__img"
              :src="fileUrl"
              :alt="value"
            />
          </a>
        </div>
        <div class="flex items-center">
          <div
            class="flex w-4 h-4 items-center justify-center form-bg-passive form-color-passive rounded-full transition filter hover:brightness-90 form-hidden group-hover:form-inline-block"
            aria-roledescription="❎"
            role="button"
            tabindex="0"
            @click="cLearValue"
          >
            <span
              class="mask-bg mask-form-remove-light bg-black mask-size-3 block w-full h-full"
            ></span>
          </div>
          <span
            class="flex w-4 h-4 items-center justify-center form-bg-success rounded-full group-hover:form-hidden"
            ><span
              class="mask-bg mask-form-check-solid form-bg-success-color mask-size-2.5 block w-full h-full"
            ></span
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import zoomerPhoto from "@/components/ImageZoomer.vue"
//import { openFile } from "../utils";
import fileApi from "@/api/filesApi";
import EntityApi from "@/api/entityApi";
const baseURL = process.env.VUE_APP_CDN_URL ? process.env.VUE_APP_CDN_URL : `${process.env.VUE_APP_API_URL}/uploads`;

export default {
  name: "FileUploadField",
  components: {
    zoomerPhoto
  },
  props: {
    textBtn: {type: String, default: 'Select file'},
    hidingLoadBtn: {type: Boolean, default: true},
    acceptTypes: {type: String, default: '*'},
    multiselect: {type: Boolean, default: false},
    getBinary: {type: Boolean, default: false},
  },
  emits: ['onError','finishUpload','startUpload'],
  mixins: [abstractField],
  data() {
    return {
      options: [],
      baseUrl: baseURL,

      showZoom: false,

      indx: 0,
      files: [],
    };
  },
  computed: {
    hasValue() {
      return (this.value && this.value?.length > 0) ? true : false;
    },
    valueIsImage() {
      let imageType = (val) => {
        let fileExtension = val.split(".").pop();
        return ["png", "jpg", "bmp"].includes(fileExtension);
      };
      return this.hasValue && imageType(this.value) ? true : false;             
    },
    fileUrl(){
      return this.item?.linkType === 'external' ? this.value : `${baseURL}/${this.value}`;
    }
  },
  methods: {
    async openUploadDialog(e) {
      this.files = [];

      if (e.target.files.length > 0){
        if(!this.multiselect){
          this.files.push(e.target.files[0]);
        } else {
          this.files = e.target.files;
        }
        this.indx = 0;

        if( this.files[this.indx] ){
          this.sendFile();
        }
      }
    },
    sendFile(){
      const file = this.files[this.indx];
      let formData = new FormData();
      formData.set("file", file);

      if(this.getBinary){
        console.log(this.files[this.indx])
        this.value = this.files[this.indx].name;
        this.$emit('finishUpload', formData);
        return
      }

      this.$emit('startUpload', null);
      fileApi.uploadFile(formData).then(res => {
        this.$emit('finishUpload', null);
        if(res){
          this.value = res.filename;
          
          ++this.indx;

          if( this.files[this.indx] ){
            this.sendFile();
          } else {
            this.files = [];
            this.indx = 0;
          }

        } else {
          this.files = [];
          this.indx = 0;

          this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),
            text: this.$t('lables.file_not_loaded'),
          }, 3000);
          this.$emit('onError',formData);
        }
      });
    },
    cLearValue() {
      if(this.getBinary){
        return
      }
      console.log("cLearValue");
      
      EntityApi.sendCustomRequest('/delete-file?file_name=' + this.value, 'post', null)
      .then(res => {
        if(!res || !res.filename){
          this.$notify({group: "alert",type:"error",title: this.$t('lables.error'),
            text: this.$t('lables.remove'),
          }, 3000);
        }
        this.value = "";
      });
      
    },
    imageZoom(){
      this.showZoom = true;
    },
    closeModal(){
      this.showZoom = false;
    },
  },
};
</script>

<style scoped lang="scss">
.form_item__img {
  max-height: 150px; 
  object-fit: cover;
}
</style>
