<template>
  <div
    class="df-preview-element-inner-wrapper col-span-12"
    :class="containerStyle"
  >
    <div
      v-if="item?.addons?.before"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 form-radius-input-l form-pl-input form-pr-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.before }}
      </div>
    </div>
    <div
      v-if="item?.addons?.after"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 order-2 form-radius-input-r form-pr-input form-pl-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.after }}
      </div>
    </div>
    <div v-html="item?.content"></div>
  </div>
</template>

<script>
import abstractField from "./abstractField";

export default {
  name: "StaticField",
  components: {},
  props: {
    
  },
  mixins: [abstractField],
  data() {
    return {};
  },
  computed: {   
    disableadClass(){
      return this.fieldDisabled ? 'form-bg-disabled form-color-disabled': 'form-bg-input form-color-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove';
    },
    
    containerStyle(){
      return [
        this.disableadClass,                 
      ];
    },    

  },
  methods: {
   
  },
};
</script>

<style scoped lang="scss"></style>
