<template>
  <div
    class="df-preview-element-inner-wrapper col-span-12"
    :class="containerStyle"
  >
    <div
      v-if="item?.addons?.before"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 form-radius-input-l form-pl-input form-pr-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.before }}
      </div>
    </div>
    <div
      v-if="item?.addons?.after"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 order-2 form-radius-input-r form-pr-input form-pl-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.after }}
      </div>
    </div>
    <div v-if="valueType == 'object'" :class="{ 'inline-list': item?.inline }">
      <base-radio
        v-for="element in localData"
        :key="element.id"
        :id="element.value"
        :value="value"
        :text="element.label"
        :trueValue="element.value"
        @valueChange="onValueChange"
      >
      </base-radio>
    </div>
    <div v-if="valueType == 'primitive'" :class="{ 'inline-list': item?.inline }">
      <base-radio
        v-for="element in localData"
        :key="element"
        :text="element"
        :id="element"
        :trueValue="element"
        :value="value"
        @valueChange="onValueChange"
      >
      </base-radio>
    </div>
  </div>
</template>

<script>
import abstractField from "./abstractField";
import BaseRadio from "@/components/BaseRadio.vue";
import { isArray, isObject } from "@/components/formGenerator/builder/utils";

export default {
  name: "RadioField",
  components: {
    BaseRadio,
  },
  props: {},
  mixins: [abstractField],
  data() {
    return {
      localData: null,
    };
  },
  computed: {
    disableadClass() {
      return this.fieldDisabled
        ? "form-bg-disabled form-color-disabled"
        : "form-border-color-input form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove";
    },
    
    containerStyle() {
      return [this.disableadClass];
    },
    valueType() {
      if (isArray(this.localData) && this.localData.length > 0) {
        if (isObject(this.localData[0])) {
          return "object";
        } else return "primitive";
      } else return null;
    },
  },
  methods: {
    getElementValue(){      
      if (!this.value) {
       return null
      }
      else {      
        return this.value
      }
    },
    onValueChange(value, id) {
        this.value = value;
      console.log("onValueChange", value, id );
    //   if (!this.value) {
    //     this.value = [];
    //   }

    //   if (value != null) {
    //     this.value.push(value);
    //   } else {
    //     this.value = this.value.filter((el) => el !== id);
    //   }
    },
  },
  created() {
    this.localData = this.item?.items;
  },
};
</script>

<style scoped lang="scss">
.inline-list {
  display: flex;
}
</style>
