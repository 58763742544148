<template>
  <div
    class="w-full flex flex-1 transition-input duration-200 border-solid form-border-width-input form-shadow-input form-input-group group form-radius-large form-bg-input form-color-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hover"
    :class="containerStyle"
  >
    <div
      v-if="item?.addons?.before"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 form-radius-input-l form-pl-input form-pr-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.before }}
      </div>
    </div>
    <div
      v-if="item?.addons?.after"
      class="form-bg-addon form-color-addon flex items-center justify-center flex-grow-0 flex-shrink-0 order-2 form-radius-input-r form-pr-input form-pl-space-addon"
    >
      <div class="contents items-center justify-center">
        {{ item.addons.after }}
      </div>
    </div>
    <textarea
      :name="item.name"
      rows="5"
      :id="fieldId"
      class="w-full bg-transparent h-full form-p-input form-radius-large form-text with-floating:form-p-input-floating border-0 form-color-input group-hover:form-color-input-hover form-autofill-default"
      :class="inputClass"
      :value="elementValue"
      :disabled="fieldDisabled"
      :readonly="fieldReadonly"
      :placeholder="item.placeholder"
      @input="onInput"
    />
  </div>
</template>

<script>
import abstractField from "./abstractField";

export default {
  name: "TextareaField",
  components: {},
  props: {},
  mixins: [abstractField],
  data() {
    return {};
  },
  computed: {
    elementValue(){
        return JSON.stringify(this.value, undefined, 2);
    },
    disableadClass() {
      return this.fieldDisabled
        ? "form-bg-disabled form-color-disabled"
        : "form-bg-input form-color-input form-border-color-input hover:form-bg-input-hover hover:form-color-input-hover hover:form-border-color-input-hover hover:form-shadow-input-hover focused:form-bg-input-focus focused:form-color-input-focus focused:form-border-color-input-focus focused:form-shadow-input-focus focused:form-ring focused-hover:form-shadow-input-hove";
    },
    containerWidth() {
      if (this.item?.size == "sm") {
        return `form-radius-input-sm`;
      } else if (this.item?.size == "lg") {
        return `form-radius-input-lg `;
      } else return "form-radius-input ";
    },
    containerStyle() {
      return [this.disableadClass, this.containerWidth];
    },
    inputClass() {
      if (this.item?.size == "sm") {
        return `form-p-input-sm form-text-sm  with-floating:form-p-input-floating-sm `;
      } else if (this.item?.size == "lg") {
        return `form-p-input-lg form-text-lg with-floating:form-p-input-floating-lg `;
      } else
        return "form-p-input form-text with-floating:form-p-input-floating";
    },
  },
  methods: {
    onInput($event) {
      // console.log(typeof $event.target.value, $event.target.value);

      this.value = $event.target.value;
    },
  },
};
</script>

<style scoped lang="scss"></style>
